import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { Button } from '@atoms';
import { Icons } from '@components/library';
import { NavLink } from 'react-router-dom';

export const Nav = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) => props.theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const MainNavListItem = styled.li<{
  $isBlue: boolean;
}>`
  margin: 0 23px;
  &:first-child {
    margin-left: 0;
  }

  > a {
    display: inline-block;
    span {
      transition: all 0.2s ease-in-out;
    }
    &:hover,
    &.active {
      span {
        color: ${({ $isBlue }) =>
          $isBlue
            ? solidColors.midnight.color
            : solidColors.upWhite.color};
      }
    }
  }
`;

export const ButtonWithMargin = styled(Button)`
  margin: 0px 10px;
`;

export const PhoneCTA = styled.a`
  display: flex;
  margin-top: 6px;
  margin-right: 5px;
`;

export const MainNavSection = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ButtonSection = styled.div`
  display: flex;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > * {
      margin: 15px 0px;
    }
  }
`;

export const StyledNavLink = styled(NavLink)<{
  $ellipse?: boolean;
  $isBlue?: boolean;
}>`
  display: flex;
  transition: all 0.2s ease-in-out;

  &:hover {
    text-decoration: none;
  }

  ${({ $ellipse, $isBlue }) =>
    $ellipse &&
    `
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        box-shadow: inset 0 0 0 1px ${
          $isBlue
            ? solidColors.midnight.color
            : solidColors.upWhite.color
        };
    `}

  &.iconHub {
    svg {
      width: 40px;
      height: 40px;
      path {
        fill: ${({ $isBlue }) =>
          $isBlue
            ? solidColors.midnight.color
            : solidColors.upWhite.color};
      }
    }
  }
`;

export const MobileStylesNavLink = styled.a<{
  $ellipse?: boolean;
  $isBlue?: boolean;
}>`
  display: flex;
  transition: all 0.2s ease-in-out;

  &:hover {
    text-decoration: none;
  }

  ${({ $ellipse, $isBlue }) =>
    $ellipse &&
    `
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        margin-right: 16px;
        border-radius: 100%;
        box-shadow: inset 0 0 0 1px ${
          $isBlue
            ? solidColors.midnight.color
            : solidColors.upWhite.color
        };
    `}

  &.iconHub {
    svg {
      width: 30px;
      height: 30px;
      path {
        fill: ${({ $isBlue }) =>
          $isBlue
            ? solidColors.midnight.color
            : solidColors.upWhite.color};
      }
    }
  }
`;

export const SecondaryNav = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  li {
    margin-left: 10px;
  }

  .hubIcon {
    fill: #ffcc00;
    path {
      fill: #ffcc00;
    }
  }
`;

export const MobileNav = styled.nav`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${(props) => props.theme.breakpoints.up('lg')} {
    display: none;
  }
`;

export const MenuMobileWrapper = styled.div`
  display: block;
  cursor: pointer;
  overflow: hidden;
  top: 10px;
  right: 24px;
  z-index: 11000;
  text-align: center;
  padding: 12px 0 0;
  background: transparent;
  border-radius: 4;
  transition: all 0.2s ease-in-out;

  width: 40px;
  height: 40px;

  .menu-box {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 14px;
  }

  .menu-inner-closed {
    top: 50%;
    margin-top: -2px;
    &,
    &:before,
    &:after {
      background-color: ${solidColors.upWhite.color};
      position: absolute;
      width: 20px;
      height: 2px;
      display: block;
    }
    & {
      width: 20px;
    }
    &:before {
      content: '';
      top: -6px;
    }
    &:after {
      content: '';
      bottom: -6px;
    }
  }

  &:hover {
    .menu-inner-closed {
      &,
      &:before,
      &:after {
        width: 20px;
      }
    }
  }

  .menu-inner-open-1 {
    transform: rotate(45deg);
    top: 50%;
    margin-top: -2px;
    &,
    &:before,
    &:after {
      background-color: ${solidColors.upWhite.color};
      position: absolute;
      width: 20px;
      height: 2px;
      display: block;
      transition: all 0.2s ease-in-out;
    }
    & {
      width: 16px;
      left: 2px;
    }
    &:before {
      top: -6px;
      left: -2px;
    }
    &:after {
      bottom: -6px;
      left: -2px;
    }
  }

  &:hover {
    .menu-inner-open-1 {
      &,
      &:before,
      &:after {
        left: 0;
        width: 20px;
      }
    }
  }
  .menu-inner-open-2 {
    transform: rotate(-45deg);
    top: 50%;
    margin-top: -2px;
    &,
    &:before,
    &:after {
      background-color: ${solidColors.upWhite.color};
      position: absolute;
      width: 20px;
      height: 2px;
      display: block;
      transition: all 0.2s ease-in-out;
    }
    & {
      width: 16px;
      left: 2px;
    }
    &:before {
      top: -6px;
      left: -2px;
    }
    &:after {
      bottom: -6px;
      left: -2px;
    }
  }

  &:hover {
    .menu-inner-open-2 {
      &,
      &:before,
      &:after {
        left: 0;
        width: 20px;
      }
    }
  }
`;

export const PhoneIcon = styled(Icons.Phone)`
  color: ${solidColors.upWhite.color};
`;

export const MenuMobile = styled.nav`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: -1;
  transform: translateY(-150%);
  -webkit-transform: translateY(-150%);
  top: 100%;
  left: 0;
  right: 0;
  padding: 0px;
  border-radius: 0px 0px 24px 24px;

  width: 100%;
  background: ${solidColors.midnight.color};

  > * {
    width: 100%;
  }
`;

export const MobileNavSection = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${solidColors.midnight.color};

  margin-bottom: 20px;

  > * {
    width: 100%;
  }
`;

export const MainMobileNavListItem = styled.li`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  text-align: left;

  &:last-child {
    border-bottom: none;
  }

  > a {
    width: 100%;
    padding: 15px 30px;
    display: inline-block;
    color: ${solidColors.upWhite.color};

    > p {
      color: ${solidColors.upWhite.color};
    }
    span {
      transition: all 0.2s ease-in-out;
    }
    &:hover,
    &.active {
      span {
        color: ${solidColors.midnight.color};
      }
    }
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const SecondaryMobileNavListItem = styled.ul`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px;

  li {
    margin-left: 10px;
  }

  .hubIcon {
    fill: #ffcc00;
    path {
      fill: #ffcc00;
    }
  }
`;

export const StyledMobileNavLink = styled.a<{
  $ellipse?: boolean;
}>`
  display: flex;
  transition: all 0.2s ease-in-out;
  margin: 0px 16px;

  &:hover {
    text-decoration: none;
  }

  &.iconHub {
    svg {
      width: 40px;
      height: 40px;
      path {
        fill: ${solidColors.midnight.color};
      }
    }
  }
`;

export const StyledIconPerson = styled(Icons.Person)<{
  $isBlue: boolean;
}>`
  color: ${({ $isBlue }) =>
    $isBlue
      ? solidColors.midnight.color
      : solidColors.upWhite.color};
`;
