import { ICardPrivateFlightSearchResult_v2 } from './CardPrivateFlightSearchResult.interfaces';
import {
  Elevation,
  Icon,
  Link,
  Typography,
} from '@wheelsup/wu-react-components';
import {
  CardMobileContainer,
  Divider,
  Flex1,
  FlightInfoCard,
  FlightInfoContainer,
  IconContainer,
  IconInfo,
  PriceAndFlightType,
  ReservationType,
} from './CardRestyledMobile.styles';
import { IconWithMargin } from './CardRestyledDesktop.styles';
import { reservationTypeMap } from './CardRestyledDesktop';
import { useViewport } from '@hooks';
import { ImageCarousel } from '@atoms';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';

const CardRestyledMobile = ({
  aircraftImages,
  aircraftTypeLine1,
  aircraftTypeLine2,
  paxAvailabilityText,
  flightDurationText,
  price,
  fuelStopText,
  selectFlight,
  flightTagColor,
  flightTagText,
  reservationType,
  taxesAndFeesText,
  memberSavingsText,
  surfaceAction,
  flightTripType,
  smallPriceText,
}: ICardPrivateFlightSearchResult_v2) => {
  const { width } = useViewport();
  const carouselWidthInRem = (width * 0.922) / 16;

  const handleClickFlightCard = () => {
    const analytics = surfaceAction?.actionAnalytics;
    if (analytics && analytics.properties) {
      trackAnalytics({
        trackingTitle: analytics?.trackingTitle,
        properties: [
          ...(analytics?.properties ?? []),
          {
            key: 'aircraftType',
            value: aircraftTypeLine2,
          },
          ...(flightTagText
            ? [{ key: 'flightTag', value: flightTagText }]
            : []),
        ],
      });
    }
    selectFlight();
  };
  return (
    <CardMobileContainer width={`${carouselWidthInRem}rem`}>
      <Elevation padding={0} variant="raisedLevel2">
        <ImageCarousel
          fit="clamp"
          imageURLs={
            aircraftImages?.map((i) => i.url) || []
          }
          heightRem={16}
          widthRem={carouselWidthInRem}
          arrowsInside={true}
          arrowsSize="small"
          borderRadius="8px 8px 0px 0px"
          tag={
            flightTagText && flightTagColor
              ? { flightTagColor, flightTagText }
              : undefined
          }
        />
        <FlightInfoCard>
          <FlightInfoContainer>
            <Flex1>
              <Typography variant={'heading02Small'}>
                {aircraftTypeLine2}
              </Typography>
              <Typography
                color="secondary"
                variant={'body02'}
              >
                {aircraftTypeLine1}
              </Typography>
            </Flex1>
            <PriceAndFlightType>
              <Typography
                variant={
                  smallPriceText
                    ? 'numberSmall'
                    : 'numberMed'
                }
              >
                {price}
              </Typography>
              <Typography
                color="secondary"
                variant="caption"
              >
                {memberSavingsText ? memberSavingsText : ''}{' '}
              </Typography>
              <Typography
                color="secondary"
                variant="caption"
              >
                {taxesAndFeesText}
              </Typography>
              <Typography color="secondary" variant="label">
                {flightTripType}
              </Typography>
            </PriceAndFlightType>
          </FlightInfoContainer>
          <IconContainer>
            <IconInfo>
              <IconWithMargin
                name="chair"
                size="s"
                color="tertiary"
              />
              <Typography
                color="secondary"
                variant={'label'}
              >
                {paxAvailabilityText}
              </Typography>
            </IconInfo>
            {flightDurationText && (
              <IconInfo>
                <IconWithMargin
                  name="clock"
                  size="s"
                  color="tertiary"
                />
                <Typography
                  color="secondary"
                  variant={'label'}
                >
                  {flightDurationText}
                </Typography>
              </IconInfo>
            )}
            {fuelStopText && (
              <IconInfo>
                <Icon
                  name="location"
                  size="s"
                  color="tertiary"
                />
                <Typography
                  color="secondary"
                  variant={'label'}
                >
                  {fuelStopText}
                </Typography>
              </IconInfo>
            )}
          </IconContainer>
        </FlightInfoCard>
        <Divider />
        <ReservationType>
          <Link
            onPress={handleClickFlightCard}
            trailingIcon="chevron-right"
          >
            {/** one off for when the tag is membership required */}
            {flightTagText === 'MEMBERSHIP REQUIRED'
              ? 'Request Information'
              : reservationTypeMap[reservationType]}
          </Link>
        </ReservationType>
      </Elevation>
    </CardMobileContainer>
  );
};

export default CardRestyledMobile;
