import { InputWrapper } from '@components/molecules/FormFlightLeg/SearchFlight.styles';
import PassengerPickerV3 from '@components/molecules/PassengerPicker/PassengerPickerV3';
import { Icon, Input } from '@wheelsup/wu-react-components';
import { useContext } from 'react';
import { FlightSearchContext } from '../FlightSearchContext/FlightSearchContext';
import { useFlightSearch } from '../FlightSearchContext/FlightSearchHooks';

const PassengerSectionV3 = ({
  legNumber,
}: {
  legNumber: number;
}) => {
  const { legs, updateLeg, isRoundTrip } = useFlightSearch(
    useContext(FlightSearchContext),
  );
  const leg = legs[legNumber];

  return (
    <InputWrapper data-name="passengerInput">
      <Input
        onClick={() =>
          updateLeg({ passengersOpen: true }, legNumber)
        }
        name={'PassengersInput'}
        label={'Passengers'}
        labelSize={'md'}
        variant={'md'}
        value={
          isRoundTrip
            ? `Depart ${
                legs[0].numberOfPassengers +
                legs[0].numberOfPets
              } - Return ${
                legs[1].numberOfPassengers +
                legs[1].numberOfPets
              }`
            : leg.numberOfPassengers + leg.numberOfPets
        }
        isReadOnly={true}
        leftIcon={
          <Icon color="secondary" name="person" size="s" />
        }
        errorMessage={
          leg.passengersError && 'ERROR MESSAGE COMING SOON'
        }
      />
      <PassengerPickerV3
        title="Passengers"
        subtitle="Select the number of passengers"
        legNumber={legNumber}
      />
    </InputWrapper>
  );
};
export default PassengerSectionV3;
