import {
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import {
  HeaderWrapper,
  ContainerWrapper,
  LinkLogo,
} from './Header.styles';
import { hostedImage } from '@constants/images/images.constants';
import { TopNavigation } from '@molecules';
import { ImageImgix } from '@atoms';
import { useViewport } from '@hooks';
import { BackgroundContext } from '@services/contextService/context';

const Header = () => {
  const { backgroundState } = useContext(BackgroundContext);
  const hasImage = backgroundState.background.hasImage;

  const [openMenu, setOpenMenu] = useState(false);
  const [blueText, setBlueText] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [className, setClassName] = useState('');
  const { width } = useViewport();
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      '(min-width: 1136px)': function () {
        gsap.to('#header', {
          scrollTrigger: {
            trigger: '#header',
            start: 1,
            end: '99999',
            scrub: true,
            toggleClass: 'sticky',
            invalidateOnRefresh: true,
            onEnter: () => setIsSticky(true),
            onLeaveBack: () => setIsSticky(false),
          },
        });
      },
    });
  }, []);

  useLayoutEffect(() => {
    const blue =
      width >= 1136 &&
      (window.location.pathname === '/signin' ||
        window.location.pathname.includes('delta-signup') ||
        window.location.pathname.includes(
          'create-account',
        ));
    setBlueText(blue);
    if (!hasImage && !isSticky) {
      setClassName('blueBackground');
    } else if (isSticky) {
      setClassName('sticky');
    } else {
      setClassName('transparent');
    }
  }, [width, isSticky, hasImage]);

  const handleOpenMenu = (val: boolean) => {
    setOpenMenu(val);
  };

  return (
    <HeaderWrapper
      id="header"
      openMenu={openMenu}
      $isSticky={isSticky || !hasImage}
      $hasImage={hasImage}
      className={className}
    >
      <ContainerWrapper>
        <LinkLogo
          to="/"
          className="linkLogo"
          $isBlue={blueText}
        >
          <ImageImgix
            src={
              blueText
                ? hostedImage.wheelsUpLogo
                : hostedImage.wheelsUpLogoWhite
            }
            alt="WheelsUp Logo"
            className="logo"
          />
        </LinkLogo>
        <TopNavigation
          isSticky={isSticky || !hasImage}
          openMenu={openMenu}
          blueText={blueText}
          setOpenMenu={handleOpenMenu}
        />
      </ContainerWrapper>
    </HeaderWrapper>
  );
};

export default Header;
