import {
  Accordion as MuiAccordion,
  AccordionActions as MuiAccordionActions,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Button as MuiButton,
  ButtonBase as MuiButtonBase,
  ButtonGroup as MuiButtonGroup,
  Card as MuiCard,
  CardActionArea as MuiCardActionArea,
  CardActions as MuiCardActions,
  CardContent as MuiCardContent,
  CardHeader as MuiCardHeader,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  ClickAwayListener as MuiClickAwayListener,
  Container as MuiContainer,
  CssBaseline as MuiCssBaseline,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  DialogTitle as MuiDialogTitle,
  Fab as MuiFab,
  FabProps as MuiFabProps,
  FormControl as MuiFormControl,
  FormControlLabel as MuiFormControlLabel,
  FormGroup as MuiFormGroup,
  FormHelperText as MuiFormHelperText,
  Grid as MuiGrid,
  GridList as MuiGridList,
  GridListTile as MuiGridListTile,
  GridListTileBar as MuiGridListTileBar,
  Icon as MuiIcon,
  IconButton as MuiIconButton,
  Input as MuiInput,
  InputAdornment as MuiInputAdornment,
  InputBase as MuiInputBase,
  InputLabel as MuiInputLabel,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  ListSubheader as MuiListSubheader,
  makeStyles as MuiMakeStyles,
  MenuItem as MuiMenuItem,
  MenuList as MuiMenuList,
  Modal as MuiModal,
  NativeSelect as MuiNativeSelect,
  NoSsr as MuiNoSsr,
  Popover as MuiPopover,
  Popper as MuiPopper,
  Radio as MuiRadio,
  Select as MuiSelect,
  Snackbar as MuiSnackbar,
  Switch as MuiSwitch,
  TabScrollButtonProps as MuiTabsScrollButtonProps,
  Tab as MuiTab,
  Tabs as MuiTabs,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  TextareaAutosize as MuiTextareaAutosize,
} from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import {
  Autocomplete as MuiAutoComplete,
  Skeleton as MuiSkeleton,
} from '@material-ui/lab';

const Accordion: any = MuiAccordion;
export { Accordion };

const AccordionActions: any = MuiAccordionActions;
export { AccordionActions };

const AccordionDetails: any = MuiAccordionDetails;
export { AccordionDetails };

const AccordionSummary: any = MuiAccordionSummary;
export { AccordionSummary };

const Autocomplete: typeof MuiAutoComplete = MuiAutoComplete;
export { Autocomplete };

const Button: any = MuiButton;
export { Button };

const ButtonBase: typeof MuiButtonBase = MuiButtonBase;
export { ButtonBase };

const ButtonGroup: any = MuiButtonGroup;
export { ButtonGroup };

const Card: any = MuiCard;
export { Card };

const CardActionArea: any = MuiCardActionArea;
export { CardActionArea };

const CardActions: any = MuiCardActions;
export { CardActions };

const CardContent: any = MuiCardContent;
export { CardContent };

const CardHeader: any = MuiCardHeader;
export { CardHeader };

const CardMedia: any = MuiCardMedia;
export { CardMedia };

const Chip: any = MuiChip;
export { Chip };

const ClickAwayListener: any = MuiClickAwayListener;
export { ClickAwayListener };

const Container: typeof MuiContainer = MuiContainer;
export { Container };

const CssBaseline: any = MuiCssBaseline;
export { CssBaseline };

const Dialog: any = MuiDialog;
export { Dialog };

const DialogActions: any = MuiDialogActions;
export { DialogActions };

const DialogContent: any = MuiDialogContent;
export { DialogContent };

const DialogContentText: any = MuiDialogContentText;
export { DialogContentText };

const DialogTitle: any = MuiDialogTitle;
export { DialogTitle };

const Fab: typeof MuiFab = MuiFab;
export { Fab };

export type FabProps = MuiFabProps;

const FormControl: any = MuiFormControl;
export { FormControl };

const FormControlLabel: any = MuiFormControlLabel;
export { FormControlLabel };

const FormGroup: any = MuiFormGroup;
export { FormGroup };

const FormHelperText: typeof MuiFormHelperText = MuiFormHelperText;
export { FormHelperText };

const Grid: any = MuiGrid;
export { Grid };

const GridList: any = MuiGridList;
export { GridList };

const GridListTile: any = MuiGridListTile;
export { GridListTile };

const GridListTileBar: any = MuiGridListTileBar;
export { GridListTileBar };

const Icon: any = MuiIcon;
export { Icon };

const IconButton: any = MuiIconButton;
export { IconButton };

const Input: any = MuiInput;
export { Input };

const InputAdornment: typeof MuiInputAdornment = MuiInputAdornment;
export { InputAdornment };

const InputBase: any = MuiInputBase;
export { InputBase };

const InputLabel: any = MuiInputLabel;
export { InputLabel };

const List: any = MuiList;
export { List };

const ListItem: any = MuiListItem;
export { ListItem };

const ListItemIcon: any = MuiListItemIcon;
export { ListItemIcon };

const ListItemText: any = MuiListItemText;
export { ListItemText };

const ListSubheader: any = MuiListSubheader;
export { ListSubheader };

const makeStyles = MuiMakeStyles;
export { makeStyles };

const MenuItem: any = MuiMenuItem;
export { MenuItem };

const MenuList: any = MuiMenuList;
export { MenuList };

const Modal: any = MuiModal;
export { Modal };

const NativeSelect: any = MuiNativeSelect;
export { NativeSelect };

const NoSsr: any = MuiNoSsr;
export { NoSsr };

const Popover: any = MuiPopover;
export { Popover };

const Popper: any = MuiPopper;
export { Popper };

const Radio: any = MuiRadio;
export { Radio };

const Select: any = MuiSelect;
export { Select };

const Skeleton: typeof MuiSkeleton = MuiSkeleton;
export { Skeleton };

const Snackbar: any = MuiSnackbar;
export { Snackbar };

const Switch: any = MuiSwitch;
export { Switch };

export type TabScrollButtonProps = MuiTabsScrollButtonProps;

const Tab: typeof MuiTab = MuiTab;
export { Tab };

const Tabs: typeof MuiTabs = MuiTabs;
export { Tabs };

const TextField: typeof MuiTextField = MuiTextField;
export { TextField };

export type TextFieldProps = MuiTextFieldProps;

const TextareaAutosize: any = MuiTextareaAutosize;
export { TextareaAutosize };

const Icons: any = MuiIcons;
export { Icons };
