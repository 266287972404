import { createGlobalStyle } from 'styled-components';
import {
  solidColors,
  surfaceColors,
} from '../constants/styles/colors.constants';

// as [styled-components] is focused on individual component customization (in our case to atoms & molecules), global style defaults can added to the following wrapper:
const GlobalStyles = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
        color: ${solidColors.midnight.color}; //wheelsupblue
        background: ${surfaceColors.backgroundUpWhite.color}; //#ffffff
        overflow-x: clip;
        font-size: 16px; // base for rem/em components
    }

    *, *:after, *:before {
        box-sizing: border-box;
    }

    a {
        background-color: transparent;
        -webkit-text-decoration-skip: objects;
        text-decoration: none;
        color: inherit;
    }

    nav {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }

    .MuiContainer-root {
        .MuiContainer-root {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .isSticky {
        display: flex;
        align-self: flex-start;
        top: 1.25rem;
        position: sticky;

        button:not([class*="Checkbox"]) {
            width: 100%;
        }
    }
`;

export default GlobalStyles;
