import {
  Link,
  Typography,
} from '@wheelsup/wu-react-components';
import { format } from 'date-fns';
import {
  CodeSection,
  HeadlineContainer,
  MiddleIcon,
  CodeContainer,
  ScrolledHeadlineContainer,
  TopAreaContainer,
  DateAndEditContainer,
  TripTypeTypography,
  DateTypography,
  TripTypeContainer,
  FlightAmountText,
} from './FlightInfoHeader.styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { updateEditSearchTab } from '@features/Fly/searchFlightSlice';
import { IFlightLeg } from '@components/types';
import { useViewport } from '@hooks';
import { BREAKPOINTS } from '@components/utils/breakpoints';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { flightTypes } from '../SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext.types';
import { flySearchResultsInstance } from '@app/rootReducer';
import { useAppSelector } from '@app/hooks';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';
import { FeatureFlags } from '@services/featureFlagService/constants';

export const formatDate = (
  departDate: Date,
  departTime?: Date,
) => {
  try {
    const formattedDate = format(
      departDate,
      "E, LLL dd',' yyyy",
    );
    if (!departTime) return formattedDate;
    const formattedTime = format(departTime, 'h:mm a');
    return formattedDate + ' at ' + formattedTime;
  } catch (err) {
    console.log(err, 'error formatting date');
    return '';
  }
};

const FlightInfoHeader = ({
  legs,
}: {
  legs: IFlightLeg[];
}) => {
  const useTod = useFeatureFlag(FeatureFlags.USE_TOD);
  const tripType = legs[0]?.flightType;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading } = useAppSelector(
    (state) => state.flySearchResults,
  );

  const goBack = () => {
    dispatch(updateEditSearchTab(tripType));
    dispatch(flySearchResultsInstance.actions.cleanState());
    navigate('/fly');
  };

  const renderLegInfo = () => {
    if (!legs) return;
    const leg = legs[0];
    const {
      date,
      departureAirport,
      arrivalAirport,
      departTime,
      flightType,
    } = leg;

    // TODO: another hack to be removed when we release refactor
    const isRoundTrip =
      flightType === 'Round-trip' ||
      flightType === 'round-trip' ||
      flightType === flightTypes.roundTrip;
    const firstLeg = legs[0];
    const lastLeg = legs[legs.length - 1];

    const renderDate = () => {
      if (!date) return '';
      if (isRoundTrip) {
        return useTod
          ? `${formatDate(date)} - ${formatDate(
              legs[1].date as Date,
            )}`
          : `${formatDate(date, departTime)} - ${formatDate(
              legs[1].date as Date,
              legs[1].departTime,
            )}`;
      }
      if (isMultiLeg && legs.length > 1) {
        return `${formatDate(
          firstLeg.date as Date,
          firstLeg.departTime,
        )} - ${formatDate(
          lastLeg.date as Date,
          lastLeg.departTime,
        )}`;
      }
      return useTod
        ? formatDate(date)
        : formatDate(date, departTime);
    };

    const onPressEditSearch = () => {
      trackAnalytics({
        trackingTitle: 'Select_EditSearch_Button',
        properties: [
          {
            key: 'flightType',
            value: flightType,
          },
          {
            key: 'airportCombination',
            value: `${departureAirport?.code} - ${arrivalAirport?.code}}`,
          },
          {
            key: 'dateAndTime',
            value: `${formatDate(
              date as Date,
              departTime,
            )}`,
          },
        ],
      });
      goBack();
    };

    // this is the worst
    const flightTypeMap: { [key: string]: string } = {
      'ROUND-TRIP': 'ROUND TRIP',
      'ONE-WAY': 'ONE WAY',
      'MULTI-LEG': 'MULTI-CITY',
      'ROUND TRIP': 'ROUND TRIP',
      'ONE WAY': 'ONE WAY',
      'MULTI-CITY': 'MULTI-CITY',
      'MULTI CITY': 'MULTI CITY',
      'MULTI LEG': 'MULTI CITY',
      'one-way': 'ONE WAY',
      'round-trip': 'ROUND TRIP',
      'multi-leg': 'MULTI-CITY',
    };
    const { width } = useViewport();
    const isDesktop = width > BREAKPOINTS.DESKTOP;

    return (
      <ScrolledHeadlineContainer>
        <TripTypeContainer>
          <TripTypeTypography
            variant={
              isDesktop ? 'heading05' : 'heading04Small'
            }
          >
            {flightTypeMap[flightType?.toUpperCase()] ||
              flightTypeMap[
                legs[0].tripType || legs[0].flightType
              ] ||
              'NO TRIP TYPE'}
          </TripTypeTypography>
          {!isDesktop && (
            <Link
              isDisabled={isLoading}
              onPress={onPressEditSearch}
            >
              Edit
            </Link>
          )}
        </TripTypeContainer>
        <CodeContainer>
          <CodeSection>
            <Typography
              variant={
                isDesktop ? 'heading01' : 'heading01Small'
              }
            >
              {departureAirport?.code}
            </Typography>
            <MiddleIcon
              color=""
              name="single-arrow"
              size="s"
            />{' '}
            <Typography
              variant={
                isDesktop ? 'heading01' : 'heading01Small'
              }
            >
              {isMultiLeg
                ? lastLeg?.arrivalAirport?.code
                : arrivalAirport?.code}
            </Typography>
            {isMultiLeg && legs.length > 1 && (
              <FlightAmountText
                color="secondary"
                variant={'body01'}
              >
                ({legs?.length} flights)
              </FlightAmountText>
            )}
          </CodeSection>
        </CodeContainer>
        <DateAndEditContainer>
          <DateTypography
            variant={isDesktop ? 'body01' : 'body02'}
          >
            {renderDate()}
          </DateTypography>
          {isDesktop && (
            <Link
              isDisabled={isLoading}
              onPress={onPressEditSearch}
            >
              Edit
            </Link>
          )}
        </DateAndEditContainer>
      </ScrolledHeadlineContainer>
    );
  };

  const isMultiLeg =
    tripType === 'multi-leg' ||
    tripType === flightTypes.multiCity;
  const leg = legs[0];

  return (
    <TopAreaContainer data-name="TopAreaContainer">
      <HeadlineContainer
        data-name="HeadlineContainer"
        key={
          // this is patch, will need to be fixed when the
          new Date(leg?.date || new Date()).toISOString() ||
          'no date'
        }
      >
        {renderLegInfo()}
      </HeadlineContainer>
    </TopAreaContainer>
  );
};

export default FlightInfoHeader;
