import mixpanel from 'mixpanel-browser';
import jwt from 'jsonwebtoken';
import { IUserPayload } from './mixpanel.interfaces';
import { getFromLocalStorage } from '@components/utils/storage';
import { IKeyValue, ObjectAccess } from '@components/types';
import { IAnalytics, IPage } from '@services/dynamicRenderingService/dynamicRendering.interfaces';
import { fetchProcessEnvVariable } from '@services/envService/envService';

export const mixpanelInit = () => {
  // Mixpanel token is set in .env file or by k8s ConfigMap
  console.log(
    'in mixpanelInit',
    fetchProcessEnvVariable('MIXPANEL_TOKEN'),
  );
  mixpanel.init(fetchProcessEnvVariable('MIXPANEL_TOKEN'), {
    debug: true,
  });
  setSuperProperties();
};

export const getAccessToken = () => {
  try {
    const token = getFromLocalStorage('access_token') || '';
    const tokenDecoded = jwt.decode(token, {
      complete: true,
    });
    return tokenDecoded?.payload;
  } catch (err) {
    return false;
  }
};

const setSuperProperties = () => {
  if (getAccessToken()) {
    const payload = getAccessToken() as IUserPayload;
    mixpanel.register({
      'Last Name': payload.lastName,
      Email: payload.user_name,
      'Member Type': payload.memberTypeCode,
      Status: payload.memberStatus,
      'User ID': payload.userId,
      'Member User Type': payload.memberUserTypeCode,
      'Client User Type': payload.clientUserType,
      'Member ID': payload.memberId,
    });
  }
};

export const trackAnalytics = (data?: IAnalytics) => {
  const buildProperties: ObjectAccess = {};

  data?.properties.map((obj: IKeyValue) => {
    const key = obj.key;
    const value = obj.value;
    buildProperties[key] = value;
  });

  if (data)
    mixpanel.track(data.trackingTitle, buildProperties);
};

export const callIdentify = (isSignup?: boolean) => {
  const accessData = getAccessToken() as IUserPayload;
  if (accessData) {
    if (isSignup) {
      mixpanel.alias(String(accessData.userId));
    }

    mixpanel.identify(String(accessData.userId));

    mixpanel.people.set({
      $last_name: accessData.lastName,
      $email: accessData.user_name,
      Status: accessData.memberStatus,
      'User ID': accessData.userId,
      'Member User Type': accessData.memberUserTypeCode,
      'Client User Type': accessData.clientUserType,
      'Member ID': accessData.memberId,
    });
  }
};

export const customPageAnalytics = (
  title: string,
  properties: [] = [],
) => {
  const analytics = {
    trackingTitle: title,
    properties,
  } as IAnalytics;

  trackAnalytics(analytics);
};

/**
 * Adds additional properties to the mixpanel analytics object based on the tracking title.
 *
 * @param pageAnalytics - The current analytics object which may be undefined.
 * @param trackingTitle - The title used to determine which additional properties to add.
 * @param data - The page data which may be used to generate additional properties.
 * @returns The updated mixpanel analytics object with additional properties.
 */
export function addAdditionalProperties(
  pageAnalytics: IAnalytics | undefined,
  trackingTitle: string,
  data: IPage | undefined
): IAnalytics {
  const propertyGenerators: { [key: string]: () => { key: string; value: any }[] } = {
    'ViewPage_Confirmation_RequestQuoteBookingFlow': () => [
      { key: 'aircraftType', value: getFromLocalStorage('aircraftType') },
    ],
    'ViewPage_Confirmation_InstantBookingFlow': () => [
      { key: 'aircraftType', value: getFromLocalStorage('aircraftType') },
    ],
    'ViewPage_SearchResults': () => [
      { key: 'returnedAircraftTypes', value: findAircraftTypes(data).join(', ') },
    ],
  };

  // Add additional props if a generator exists for the trackingTitle
  const additionalProperties = propertyGenerators[trackingTitle]?.() || [];

  return {
    ...pageAnalytics,
    properties: [...(pageAnalytics?.properties ?? []), ...additionalProperties],
  } as IAnalytics;
}

/**
 * Recursively searches through a nested object structure to find and collect all values associated with the `aircraftTypeLine2` property.
 *
 * @param data - The input data object to search through.
 * @returns An array of strings containing all found `aircraftTypeLine2` values.
 */
function findAircraftTypes(data: any): string[] {
  const result: string[] = [];
  const queue = [data];

  while (queue.length > 0) {
    // Pop the first element from the queue
    const current = queue.shift();

    // If the current object has aircraftTypeLine2, add it to the results
    if (current?.aircraftTypeLine2) {
      result.push(current.aircraftTypeLine2);
    }

    // Add nested objects to the queue to continue searching
    if (current && typeof current === 'object') {
      Object.values(current).forEach(value => {
        if (typeof value === 'object') queue.push(value);
      });
    }
  }

  return result;
}
