import { legends } from '../SubPageDatePicker.interface';
import {
  DayPicker,
  DateFormatter,
  SelectSingleEventHandler,
  MonthChangeEventHandler,
} from 'react-day-picker';
import { format } from 'date-fns';
import 'react-day-picker/dist/style.css';
import {
  DatePickerContainer,
  DatePopupContainer,
} from '../SubPageDatePicker.style';
import CustomSubpage from '@components/molecules/CustomSubpage/CustomSubpage';
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ReactDayPickerStyleOverrides } from './CalendarPricing.style';

import { FlightSearchContext } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext';
import {
  endOfNextMonth,
  startOfCurrentMonth,
} from '../dateHelper';
import { DateTime } from 'luxon';
import { useOnClickOutside, useViewport } from '@hooks';
import { useFlightSearch } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchHooks';
import { TSharedProps } from '@components/molecules/SubPageFlightSearchV2/LegContainers/CalendarSection';
import { DateWithPrice } from './DateWithPrice/DateWithPrice';
import {
  extractAndFormatPrice,
  requiresQuote,
} from './DateWithPrice/helpers';
import { flightTypes } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext.types';
import { theme } from '@constants/styles/theme.constants';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';
import { FeatureFlags } from '@services/featureFlagService/constants';

const formatWeekdayName: DateFormatter = (day) => {
  return format(day, 'iii');
};

const formatDisplayDate = (day: Date) => {
  return format(day, 'EEEE, MMMM d');
};

const CalendarPricingSingle = ({
  sharedProps,
  legNumber,
}: {
  legNumber: number;
  sharedProps: TSharedProps;
}) => {
  const {
    legs,
    updateDisplayDate,
    updateLeg,
    updatePricingRequest,
    flightType,
    pricingRequest,
  } = useFlightSearch(useContext(FlightSearchContext));
  const { width } = useViewport();

  const leg = legs[legNumber];
  const popupRef = useRef(null);

  const useTod = useFeatureFlag(FeatureFlags.USE_TOD);

  const [
    footerPriceDisplay,
    setFooterPriceDisplay,
  ] = useState('');

  const handleContinue = () => {
    updateLeg(
      {
        ...leg,
        dateOpen: false,
        date: leg.date,
        ...(useTod
          ? undefined
          : {
              timeOpen: true,
            }),
      },
      legNumber,
    );
  };

  const handleMonthChange: MonthChangeEventHandler = (
    newStartDate: Date,
  ) => {
    const newEndDate = endOfNextMonth(newStartDate);
    updatePricingRequest({
      ...pricingRequest,
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const {
    pricingData,
    isPricingDataLoading,
    dateDecorationMap,
    decorationMapLoading,
  } = sharedProps;

  const handleFooterPriceDisplayChange = (
    selectedDate: Date | undefined,
  ) => {
    // Return early if pricing data is still loading
    if (isPricingDataLoading) {
      setFooterPriceDisplay('');
      return;
    }
    // Decide whether to display a quote or price
    if (
      (selectedDate &&
        requiresQuote(selectedDate, dateDecorationMap)) ||
      flightType === flightTypes.multiCity
    ) {
      setFooterPriceDisplay('Request Quote');
    } else if (selectedDate) {
      const priceDisplay = extractAndFormatPrice(
        selectedDate,
        pricingData,
      );
      setFooterPriceDisplay(priceDisplay);
    }
  };

  // when we finish loading, want to re-evaluate the footer price display
  useEffect(() => {
    handleFooterPriceDisplayChange(leg.date as Date);
  }, [isPricingDataLoading, decorationMapLoading]);

  const handleSelectDate: SelectSingleEventHandler = (
    selectedDate,
  ) => {
    handleFooterPriceDisplayChange(selectedDate);
    if (selectedDate) {
      updateDisplayDate(
        `${formatDisplayDate(selectedDate as Date)}`,
      );
    } else {
      updateDisplayDate('');
    }
    updateLeg({ ...leg, date: selectedDate }, legNumber);
  };

  useOnClickOutside(popupRef, () => {
    updateLeg({ ...leg, dateOpen: false }, legNumber);
    // Reset start/end month to current month or month of first leg
    const newStartMonth =
      getCorrectStartingMonth() || new Date();
    updatePricingRequest({
      ...pricingRequest,
      startDate: startOfCurrentMonth(newStartMonth),
      endDate: endOfNextMonth(newStartMonth),
    });
  });

  const isDesktop = width > theme.breakpoints.values.md;

  const getDisabledBeforeDate = () => {
    // if we are multi leg and thsi is the first trip, we want to disable dates before today
    // if we are one way, we want to disable dates before today
    if (
      flightType === flightTypes.oneWay ||
      legNumber === 0
    ) {
      return DateTime.now().startOf('day').toJSDate();
    }
    // if we are multi leg and this is not the first trip, we want to disable dates before the previous trip
    return (
      legs[legNumber - 1].date ||
      DateTime.now().startOf('day').toJSDate()
    );
  };

  const getCorrectStartingMonth = () => {
    // if we are on the second leg, we want to open calendar to month of first leg
    if (legs[legNumber].date) return legs[legNumber].date;
    if (
      flightType === flightTypes.multiCity &&
      legNumber > 0
    ) {
      return legs[legNumber - 1].date;
    }
    return new Date();
  };

  return (
    <DatePickerContainer data-name="DatePickerContainer">
      <DatePopupContainer
        data-name="DatePopupContainer"
        ref={popupRef}
        useTod={useTod}
        multiLeg={flightType === flightTypes.multiCity}
      >
        <CustomSubpage
          width={'unset'}
          action={handleContinue}
          isButtonDisabled={!leg.date}
          legends={legends}
          tripType={flightType || flightTypes.oneWay}
          isCalendar={true}
          selectedPrice={footerPriceDisplay}
          showPrice={!!footerPriceDisplay}
        >
          <ReactDayPickerStyleOverrides>
            <DayPicker
              id="test"
              disabled={{
                before: getDisabledBeforeDate(),
              }}
              fromMonth={new Date()}
              mode={'single'}
              numberOfMonths={isDesktop ? 2 : 1}
              defaultMonth={
                getCorrectStartingMonth() as Date
              }
              selected={leg.date as Date}
              onMonthChange={handleMonthChange}
              onSelect={handleSelectDate}
              formatters={{ formatWeekdayName }}
              components={{
                // eslint-disable-next-line react/display-name
                DayContent: (props) => (
                  <DateWithPrice
                    dayContentProps={props}
                    sharedProps={sharedProps}
                    legNumber={legNumber}
                  />
                ),
              }}
            />
          </ReactDayPickerStyleOverrides>
        </CustomSubpage>
      </DatePopupContainer>
    </DatePickerContainer>
  );
};

export { CalendarPricingSingle };
