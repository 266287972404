import {
  Nav,
  SecondaryNav,
  StyledNavLink,
  ButtonSection,
  MainNavListItem,
  MainNavSection,
  PhoneCTA,
  ButtonWithMargin,
  MenuMobileWrapper,
  MobileNav,
  MenuMobile,
  MobileNavSection,
  StyledMobileNavLink,
  MainMobileNavListItem,
  StyledIconPerson,
  PhoneIcon,
  MobileStylesNavLink,
} from './TopNavigation.styles';
import { Button, Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { Icons } from '@library';
import { paths } from '@routing/routerPaths';
import IconHub from '@atoms/SVGIcons/iconHub';
import { isAuthenticated } from '@services/authService/authService';
import topNavData from './UnauthenticatedTopNav.json';
import { ListAccordionItem } from '@molecules';
import { solidColors } from '@constants/styles/colors.constants';
import {
  getFromLocalStorage,
  saveDataInLocalStorage,
} from '@components/utils/storage';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Link,
  Typography as SharedLibraryTypography,
} from '@wheelsup/wu-react-components';

interface INav {
  openMenu: boolean;
  setOpenMenu: (val: boolean) => void;
  blueText: boolean;
  isSticky: boolean;
  width?: number;
}

const TopNavigation = (props: INav) => {
  const { openMenu, setOpenMenu, isSticky } = props;
  const navigate = useNavigate();
  const isProd = process.env.NODE_ENV === 'production';
  const navHeaderType = 'body2Bold';
  const typedHeaderNav = navHeaderType as keyof typeof typography;
  const colorObj =
    !isSticky && props.blueText
      ? solidColors.midnight
      : solidColors.upWhite;

  const isAmexLandingPage =
    window.location.pathname ===
    '/partner-program/amex-landing-page'
      ? true
      : false;

  const handleMenuClick = () => {
    // below controls the animation of the menu with JS
    // normally keyframes should be used, but this particular
    // animation is not supported by safari using keyframes
    const menuMobile = document.getElementById(
      'menu-mobile',
    );
    if (openMenu && menuMobile) {
      menuMobile.style.transform = 'translateY(-150%)';
      menuMobile.style.transition = 'all 0.6s ease-in-out';
    } else if (menuMobile) {
      menuMobile.style.transform = 'translateY(0)';
      menuMobile.style.transition = 'all 0.6s ease-in-out';
    }
    setOpenMenu(!openMenu);
  };

  if (isAmexLandingPage) {
    saveDataInLocalStorage('amex_registration_flag', true);
    if (!getFromLocalStorage('_data')) {
      navigate(paths.AMEX_ELIGIBILITY);
      return null;
    }
    const {
      benefitFamily,
      amexToken,
    } = getFromLocalStorage('_data');
    const requestInfoLink = `https://pages.wheelsup.com/${benefitFamily}.html?amex_token=${amexToken}`;

    return (
      <Nav>
        <MainNavSection>
          <ButtonWithMargin
            action={{
              actionMethod: () => {
                window.open(requestInfoLink);
              },
            }}
            styleType={'primary'}
            height={'2.5rem'}
            title="Request Info"
          />
          <ButtonWithMargin
            action={{
              actionMethod: () => {
                window.open('/signin', '_self');
              },
            }}
            styleType={'secondary'}
            height={'2.5rem'}
            title="Connect Your Wheels Up Account"
          />
        </MainNavSection>
      </Nav>
    );
  }

  return (
    <>
      <Nav>
        {isAuthenticated() ? (
          <MainNavSection>
            <MainNavListItem
              $isBlue={!isSticky && props.blueText}
            >
              <NavLink to={paths.HOME}>
                <Typography
                  variant={typography[typedHeaderNav]}
                  color={colorObj}
                >
                  HOME
                </Typography>
              </NavLink>
            </MainNavListItem>
            <MainNavListItem
              $isBlue={!isSticky && props.blueText}
            >
              <NavLink to={paths.FLY}>
                <Typography
                  variant={typography[typedHeaderNav]}
                  color={colorObj}
                >
                  FLY
                </Typography>
              </NavLink>
            </MainNavListItem>
            <MainNavListItem
              $isBlue={!isSticky && props.blueText}
            >
              <NavLink to={paths.MY_TRIPS}>
                <Typography
                  variant={typography[typedHeaderNav]}
                  color={colorObj}
                >
                  TRIPS
                </Typography>
              </NavLink>
            </MainNavListItem>
            <MainNavListItem
              $isBlue={!isSticky && props.blueText}
            >
              <NavLink to={paths.MEMBERS}>
                <Typography
                  variant={typography[typedHeaderNav]}
                  color={colorObj}
                >
                  MEMBERS
                </Typography>
              </NavLink>
            </MainNavListItem>
          </MainNavSection>
        ) : (
          <>
            <MainNavSection>
              {topNavData.header.map((item) => (
                <MainNavListItem
                  key={item.title}
                  $isBlue={!isSticky && props.blueText}
                >
                  <Link
                    href={`${
                      isProd
                        ? 'https://wheelsup.com'
                        : 'https://staging.wheelsup.com'
                    }${item.subitems[0].url}`}
                  >
                    <Typography
                      color={
                        isSticky
                          ? solidColors.upWhite
                          : solidColors.midnight
                      }
                      variant={typography.body2Bold}
                    >
                      {item.title.toUpperCase()}
                    </Typography>
                  </Link>
                </MainNavListItem>
              ))}
            </MainNavSection>
            <MainNavSection>
              <ButtonSection>
                <PhoneCTA href="tel:855-FLY-8760">
                  <Icons.Phone />
                  <Typography variant={typography.body1}>
                    855-FLY-8760
                  </Typography>
                </PhoneCTA>

                <ButtonWithMargin
                  action={{
                    actionMethod: () => {
                      window.open(
                        `${
                          isProd
                            ? 'https://wheelsup.com'
                            : 'https://staging.wheelsup.com'
                        }/request-info`,
                      );
                    },
                  }}
                  styleType={'secondary'}
                  height={'2.5rem'}
                  title="Request Info"
                />
              </ButtonSection>
            </MainNavSection>
          </>
        )}
        {isAuthenticated() && (
          <SecondaryNav>
            <>
              <li>
                <StyledNavLink
                  $isBlue={!isSticky && props.blueText}
                  to={paths.ACCOUNT}
                  $ellipse={true}
                  title="Navigate to the account page"
                  aria-label="Go to Account"
                >
                  <StyledIconPerson
                    $isBlue={!isSticky && props.blueText}
                    alt="Account Logo"
                  />
                </StyledNavLink>
              </li>

              <li>
                <StyledNavLink
                  $isBlue={!isSticky && props.blueText}
                  to={paths.HUB}
                  $ellipse={true}
                  className="iconHub"
                  title="Navigate to the hub"
                  aria-label="Go to Hub"
                >
                  <IconHub />
                </StyledNavLink>
              </li>
            </>
          </SecondaryNav>
        )}
      </Nav>
      <MobileNav>
        <MobileStylesNavLink
          $isBlue={!isSticky && props.blueText}
          href={paths.HUB}
          $ellipse={true}
          className="iconHub"
          title="Navigate to the hub"
          aria-label="Go to Hub"
        >
          <IconHub />
        </MobileStylesNavLink>
        <MenuMobileWrapper onClick={handleMenuClick}>
          <div className="menu-box">
            <div
              className={
                openMenu
                  ? 'menu-inner-open-1'
                  : 'menu-inner-closed'
              }
            />
            <div
              className={
                openMenu ? 'menu-inner-open-2' : ''
              }
            />
          </div>
        </MenuMobileWrapper>
        <MenuMobile id="menu-mobile">
          {isAuthenticated() ? (
            <MobileNavSection id="MobileNavSection">
              <ul>
                <MainMobileNavListItem>
                  <StyledMobileNavLink
                    href={paths.HOME}
                    onClick={handleMenuClick}
                  >
                    <SharedLibraryTypography
                      variant="body01Bold"
                      color="interactivePrimary"
                    >
                      Home
                    </SharedLibraryTypography>
                  </StyledMobileNavLink>
                </MainMobileNavListItem>
                <MainMobileNavListItem>
                  <StyledMobileNavLink
                    href={paths.FLY}
                    onClick={handleMenuClick}
                  >
                    <SharedLibraryTypography
                      variant="body01Bold"
                      color="interactivePrimary"
                    >
                      Fly
                    </SharedLibraryTypography>
                  </StyledMobileNavLink>
                </MainMobileNavListItem>
                <MainMobileNavListItem>
                  <StyledMobileNavLink
                    href={paths.MY_TRIPS}
                    onClick={handleMenuClick}
                  >
                    <SharedLibraryTypography
                      variant="body01Bold"
                      color="interactivePrimary"
                    >
                      Trips
                    </SharedLibraryTypography>
                  </StyledMobileNavLink>
                </MainMobileNavListItem>
                <MainMobileNavListItem>
                  <StyledMobileNavLink
                    href={paths.MEMBERS}
                    onClick={handleMenuClick}
                  >
                    <SharedLibraryTypography
                      variant="body01Bold"
                      color="interactivePrimary"
                    >
                      Members
                    </SharedLibraryTypography>
                  </StyledMobileNavLink>
                </MainMobileNavListItem>
                <MainMobileNavListItem>
                  <StyledMobileNavLink
                    href={paths.ACCOUNT}
                    onClick={handleMenuClick}
                  >
                    <SharedLibraryTypography
                      variant="body01Bold"
                      color="interactivePrimary"
                    >
                      Account
                    </SharedLibraryTypography>
                  </StyledMobileNavLink>
                </MainMobileNavListItem>
              </ul>
            </MobileNavSection>
          ) : (
            <MobileNavSection>
              {topNavData.header.map((item, index) => {
                const key = `${item.title}-${index}`;
                return (
                  <ListAccordionItem
                    key={key}
                    title={item.title.toUpperCase()}
                    headerType={'header-menu'}
                    typographyColor={solidColors.upWhite}
                    body={item.subitems.map(
                      (subitem, idx) => {
                        const subKey = `${subitem.title}-${idx}`;
                        return (
                          <MainMobileNavListItem
                            key={subKey}
                          >
                            <Link
                              href={`${
                                isProd
                                  ? 'https://wheelsup.com'
                                  : 'https://staging.wheelsup.com'
                              }${subitem.url}`}
                              onPress={() =>
                                setOpenMenu(false)
                              }
                            >
                              <SharedLibraryTypography
                                variant="body02Bold"
                                color="interactivePrimary"
                              >
                                {subitem.title.toUpperCase()}
                              </SharedLibraryTypography>
                            </Link>
                          </MainMobileNavListItem>
                        );
                      },
                    )}
                  />
                );
              })}
            </MobileNavSection>
          )}
          <ButtonSection>
            {!isAuthenticated() && (
              <Button
                action={{
                  actionMethod: () => {
                    window.open(
                      `${
                        isProd
                          ? 'https://wheelsup.com'
                          : 'https://staging.wheelsup.com'
                      }/request-info`,
                    );
                  },
                }}
                styleType={'primary'}
                height={'2.5rem'}
                title="Request Info"
              />
            )}
            <PhoneCTA href="tel:855-FLY-8760">
              <PhoneIcon />
              <SharedLibraryTypography
                color="interactivePrimary"
                variant="body01"
              >
                855-FLY-8760
              </SharedLibraryTypography>
            </PhoneCTA>
          </ButtonSection>
        </MenuMobile>
      </MobileNav>
    </>
  );
};

export default TopNavigation;
