import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import React, { Suspense, useRef } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import AuthRoute from './components/AuthRoute';
import { ILinkedRoute } from './routesTypes';
import LoadingPage from '@components/molecules/NoPathFoundPage/LoadingPage';
import { MainLayout } from '../shared/components/organisms';
import { MainLayoutRoutes } from './routerPaths';
import NoPathFoundPage from '@components/molecules/NoPathFoundPage/NoPathFoundPage';
import { getFromLocalStorage } from '@components/utils/storage';
import { gsap } from 'gsap';
import { Loader } from '@components/molecules';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';

const completeCall = (target: object, parent: object) => {
  gsap.set(target, { clearProps: 'all' });
  parent && gsap.set(parent, { clearProps: 'all' });
};

const Router = () => {
  const parentNode = useRef(null);
  const location = useLocation();

  const onEnterHandler = (node: object) => {
    gsap.killTweensOf(node);
    // Set initial position and styles
    gsap.set('footer', {
      autoAlpha: 0,
      display: 'none',
    });
    gsap.set('#middle-children', {
      // position: 'absolute',
      left: 0,
      right: 0,
      width: '100%',
      y: 50,
      autoAlpha: 0,
    });
    gsap.set(parentNode.current, {
      overflow: 'hidden',
    });
    // Create the animation for the incoming component
    gsap.to('#middle-children', {
      duration: 1,
      autoAlpha: 1,
      width: '100%',
      y: 0,
      onComplete: completeCall,
      onCompleteParams: [node, parentNode.current],
    });
  };

  const onExitHandler = (node: object) => {
    gsap.killTweensOf(node);
    // Set initial position and styles
    gsap.set('#middle-children', {
      position: 'static',
      left: 0,
      width: '100%',
    });
    // Create the animation for the incoming component
    gsap.to('#middle-children', {
      duration: 0.6,
      autoAlpha: 0,
      y: -140,
      width: '100%',
    });
    gsap.to('footer', {
      duration: 0.6,
      autoAlpha: 1,
      delay: 2,
      display: 'block',
    });
  };

  // shouldShowLoadingPage is used to determine if we should show the loading page in a mobile webview.
  // This view will NOT show the header and footer of the app and is determined by mobile device localStorage.
  const shouldShowLoadingPage = getFromLocalStorage(
    'shouldShowLoadingPage',
  );

  // shouldShowLoadingPageWeb is used to determine if we should show the loading page in a web iFrame.
  // This view will show the header and footer of the app and is determined by web localStorage.
  const shouldShowLoadingPageWeb = getFromLocalStorage(
    'shouldShowLoadingPageWeb',
  );
  const isWebview = location.search.includes('wupWebview');

  const authRoute = (
    route: ILinkedRoute,
    {
      isPrivate = true,
      showHeader = true,
      showFooter = true,
    }: ILinkedRoute,
  ) => {
    const routeData = {
      ...route,
      isPrivate,
      showHeader: showHeader && !isWebview,
      showFooter: showFooter && !isWebview,
    };
    return (
      <MainLayout
        showHeader={routeData.showHeader}
        showFooter={routeData.showFooter}
      >
        <TransitionGroup component={null}>
          <CSSTransition
            key={location.pathname}
            timeout={2000}
            onEnter={onEnterHandler}
            onExit={onExitHandler}
          >
            <AuthRoute linkedRoute={routeData} />
          </CSSTransition>
        </TransitionGroup>
      </MainLayout>
    );
  };

  const noPageFoundRoute = {
    path: '*',
    element:
      shouldShowLoadingPage || shouldShowLoadingPageWeb ? (
        <LoadingPage />
      ) : (
        <NoPathFoundPage />
      ),
  };

  const mainRoutes = Object.values(MainLayoutRoutes).map(
    (route: ILinkedRoute) => {
      // Calling this hook conditionally / inside a callback is required (for now) to avoid changes to how flags are handled.
      // In practice, this won't affect the order of hooks called since the routes are static and won't change between renders.
      const canRenderRoute = route.flag
        ? useFeatureFlag(route.flag)
        : true;

      if (!canRenderRoute) return {};

      return {
        path: route.path.slice(1, route.path.length),
        element: (
          <Suspense fallback={<Loader />}>
            {authRoute(route, route)}
          </Suspense>
        ),
      };
    },
  );

  const routes = [...mainRoutes, noPageFoundRoute];

  return useRoutes(routes);
};

export default React.memo(Router);
